

table {
    width: 100%;
    border-collapse: collapse; /* Ensures that borders between cells are merged */
}

th, td {
    border: 1px solid black; /* Adjust color and width as needed */
    padding: 8px; /* Adjust padding as needed */
}

th {
    background-color: #f2f2f2; /* Adjust color as needed */
}



